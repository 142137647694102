const canvasSketch = require("canvas-sketch");
const math = require("canvas-sketch-util/math");
const random = require("canvas-sketch-util/random");
const Color = require("canvas-sketch-util/color");
const risoColors = require("riso-colors");

// const SimplexNoise = require("simplex-noise");

const seed = random.getRandomSeed();

const settings = {
  dimensions: [2048, 4096],
  name: seed
};

// const simplex = new SimplexNoise();

class Boid {
  constructor(x, y) {
    this.position = { x, y };
    this.velocity = { x: random.range(-1, 1), y: random.range(-1, 1) };
    this.acceleration = { x: 0, y: 0 };
    this.maxSpeed = random.range(1, 3); // Random speed between 1 and 3
    this.perceptionRadius = random.range(33, 120); // Random perception between 33 and 120
    this.maxForce = random.range(0.05, 0.3); // Random force between 0.1 and 0.3
  }

  applyForce(force) {
    this.acceleration.cx += force.cx;
    this.acceleration.cy += force.cy;
  }

  edges(width, height) {
    if (this.position.x > width) this.position.x = 0;
    if (this.position.x < 0) this.position.x = width;
    if (this.position.y > height) this.position.y = 0;
    if (this.position.y < 0) this.position.y = height;
  }

  flock(boids) {
    let alignment = this.align(boids);
    let cohesion = this.cohere(boids);
    let separation = this.separate(boids);

    this.applyForce(alignment);
    this.applyForce(cohesion);
    this.applyForce(separation);
  }

  align(boids) {
    let steering = { x: 0, y: 0 };
    let total = 0;

    for (let boid of boids) {
      let distance = Math.sqrt(
        (this.position.x - boid.position.x) ** 2 +
          (this.position.y - boid.position.y) ** 2
      );
      if (boid !== this && distance < this.perceptionRadius) {
        steering.x += boid.velocity.x;
        steering.y += boid.velocity.y;
        total++;
      }
    }

    if (total !== 0) {
      steering.x /= total;
      steering.y /= total;

      steering.x -= this.velocity.x;
      steering.y -= this.velocity.y;

      const magnitude = Math.sqrt(steering.x ** 2 + steering.y ** 2);
      if (magnitude > this.maxForce) {
        steering.x *= this.maxForce / magnitude;
        steering.y *= this.maxForce / magnitude;
      }
    }

    return steering;
  }

  cohere(boids) {
    let steering = { x: 0, y: 0 };
    let total = 0;

    for (let boid of boids) {
      let distance = Math.sqrt(
        (this.position.x - boid.position.x) ** 2 +
          (this.position.y - boid.position.y) ** 2
      );
      if (boid !== this && distance < this.perceptionRadius) {
        steering.x += boid.position.x;
        steering.y += boid.position.y;
        total++;
      }
    }

    if (total !== 0) {
      steering.x /= total;
      steering.y /= total;

      steering.x -= this.position.x;
      steering.y -= this.position.y;

      const magnitude = Math.sqrt(steering.x ** 2 + steering.y ** 2);
      if (magnitude > this.maxSpeed) {
        steering.x *= this.maxSpeed / magnitude;
        steering.y *= this.maxSpeed / magnitude;
      }
    }

    return steering;
  }

  separate(boids) {
    let steering = { x: 0, y: 0 };
    let total = 0;

    for (let boid of boids) {
      let distance = Math.sqrt(
        (this.position.x - boid.position.x) ** 2 +
          (this.position.y - boid.position.y) ** 2
      );
      if (boid !== this && distance < this.perceptionRadius) {
        let diff = {
          x: this.position.x - boid.position.x,
          y: this.position.y - boid.position.y
        };
        diff.x /= distance;
        diff.y /= distance;

        steering.x += diff.x;
        steering.y += diff.y;

        total++;
      }
    }

    if (total !== 0) {
      steering.x /= total;
      steering.y /= total;

      const magnitude = Math.sqrt(steering.x ** 2 + steering.y ** 2);
      if (magnitude > this.maxSpeed) {
        steering.x *= this.maxSpeed / magnitude;
        steering.y *= this.maxSpeed / magnitude;
      }
    }

    return steering;
  }

  update() {
    this.position.x += this.velocity.x;
    this.position.y += this.velocity.y;

    this.velocity.x += this.acceleration.x;
    this.velocity.y += this.acceleration.y;

    const magnitude = Math.sqrt(this.velocity.x ** 2 + this.velocity.y ** 2);
    if (magnitude > this.maxSpeed) {
      this.velocity.x *= this.maxSpeed / magnitude;
      this.velocity.y *= this.maxSpeed / magnitude;
    }

    this.acceleration.x = 0;
    this.acceleration.y = 0;
  }

  show(context) {
    drawCircle({
      context: context,
      radius: 20,
      x: this.position.x,
      y: this.position.y,
      fill: random.pick(risoColors).hex,
      stroke: random.pick(risoColors).hex
    });
  }
}

const rectColors = Array(6)
  .fill()
  .map(() => random.pick(risoColors));

const drawGrid = ({ context, width, height, gridSize, gridColor }) => {
  context.strokeStyle = gridColor;
  context.lineWidth = 1;
  for (let x = 0; x <= width; x += gridSize) {
    context.beginPath();
    context.moveTo(x, 0);
    context.lineTo(x, height);
    context.stroke();
  }
  for (let y = 0; y <= height; y += gridSize) {
    context.beginPath();
    context.moveTo(0, y);
    context.lineTo(width, y);
    context.stroke();
  }
};

const applyGrain = ({ context, width, height, grainSize, grainOpacity }) => {
  const grainCanvas = document.createElement("canvas");
  grainCanvas.width = width;
  grainCanvas.height = height;
  const grainContext = grainCanvas.getContext("2d");
  for (let y = 0; y < height; y += grainSize) {
    for (let x = 0; x < width; x += grainSize) {
      const grayValue = Math.floor(random.range(0, 255));
      grainContext.fillStyle = `rgba(${grayValue}, ${grayValue}, ${grayValue}, ${grainOpacity})`;
      grainContext.fillRect(x, y, grainSize, grainSize);
    }
  }
  context.drawImage(grainCanvas, 0, 0, width, height);
};

const drawCircle = ({ context, radius, x, y, fill, stroke }) => {
  context.beginPath();
  context.arc(x, y, radius, 0, Math.PI * 2);
  context.fillStyle = fill;
  context.strokeStyle = stroke;
  context.fill();
  context.stroke();
};

const drawSkewedRect = (context, w, h, degrees) => {
  const angle = math.degToRad(degrees);
  const rx = Math.cos(angle) * w;
  const ry = Math.sin(angle) * w;
  context.save();
  context.translate(rx * -0.5, (ry + h) * -0.5);
  context.beginPath();
  context.moveTo(0, 0);
  context.lineTo(rx, ry);
  context.lineTo(rx, ry + h);
  context.lineTo(0, h);
  context.closePath();
  context.stroke();
  context.restore();
};

// main rendering function
const sketch = ({ width, height }) => {
  const num = Math.round(random.range(0, 99));
  const degrees = random.range(-30, 42, 60, 90, 180, 222, -90, 0);
  const numCircles = Math.round(random.range(0, 99));
  const bgColor = random.pick(risoColors).hex;

  const mask = {
    radius: width * 0.4,
    sides: 4,
    x: width * 0.5,
    y: height * 0.5
  };

  const rects = Array(num)
    .fill()
    .map(() => ({
      x: random.range(0, width),
      y: random.range(0, height),
      w: random.range(0, width),
      h: random.range(0, height),
      fill: random.pick(rectColors).hex,
      stroke: random.pick(rectColors).hex,
      blend: random.pick(["normal", "multiply", "screen", "overlay", "darken"])
    }));

  const circles = Array(numCircles)
    .fill()
    .map(() => ({
      cx: random.range(0, width),
      cy: random.range(0, height),
      r: random.range(21, 180),
      fill: random.pick(rectColors).hex,
      stroke: random.pick(rectColors).hex,
      blend: random.pick(["normal", "multiply", "screen", "overlay", "darken"])
    }));

  // Create a number of Boids (circles)
  const boids = [];

  // Number of iterations to simulate the flocking behavior
  const iterations = 32;
  for (let i = 0; i < iterations; i++) {
    boids.forEach((boid) => {
      boid.edges(width, height);
      boid.flock(boids);
      boid.update();
    });
  }

  for (let i = 0; i < numCircles; i++) {
    let cx = random.range(0, width);
    let cy = random.range(0, height);

    let boid = new Boid(cx, cy);
    boids.push(boid);
  }

  return ({ context }) => {
    drawGrid({
      context,
      width,
      height,
      gridSize: 50,
      gridColor: "rgba(255, 255, 255, 0.40)"
    });

    context.fillStyle = bgColor;
    context.fillRect(0, 0, width, height);

    context.save();
    context.translate(mask.x, mask.y);
    context.beginPath();
    context.moveTo(-1000, -2000);
    context.lineTo(-1000, 2000);
    context.lineTo(1000, 2000);
    context.lineTo(1000, -2000);
    context.closePath();
    context.clip();

    for (const rect of rects) {
      context.save();
      context.translate(-mask.x, -mask.y);
      context.translate(rect.x, rect.y);
      context.strokeStyle = rect.stroke;
      context.fillStyle = rect.fill;
      context.lineWidth = 12;
      context.globalCompositeOperation = rect.blend;
      drawSkewedRect(context, rect.w, rect.h, degrees);
      const shadowColor = Color.offsetHSL(rect.fill, 0, 0, -50);
      shadowColor.rgba[3] = 0.2;
      context.shadowColor = Color.style(shadowColor.rgba);
      context.shadowOffsetX = -10;
      context.shadowOffsetY = 10;
      context.fill();
      context.shadowColor = null;
      context.stroke();
      context.lineWidth = random.range(4, 5);
      context.strokeStyle = rect.blend;
      context.stroke();
      context.restore();
    }

    for (const circle of circles) {
      context.save();
      context.translate(-mask.x, -mask.y);
      context.translate(circle.cx, circle.cy);
      context.strokeStyle = circle.stroke;
      context.fillStyle = circle.fill;
      context.lineWidth = 12;
      context.globalCompositeOperation = circle.blend;
      drawCircle({
        context,
        radius: circle.r,
        x: circle.cx,
        y: circle.cy,
        fill: circle.fill,
        stroke: circle.stroke
      });
      context.restore();
    }

    boids.forEach((boid) => {
      boid.show(context);
      boid.update();
      boid.edges();
      boid.flock(boids);
    });

    context.restore();

    context.save();
    context.translate(mask.x, mask.y);
    context.lineWidth = 24;
    context.strokeStyle = rectColors[0].hex;
    context.stroke();
    context.restore();

    applyGrain({
      context,
      width,
      height,
      grainSize: 2,
      grainOpacity: 0.17
    });
  };
};

canvasSketch(sketch, settings);
